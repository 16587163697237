import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {
    GatsbyImage, getImage
} from 'gatsby-plugin-image';
import SecondaryButton from './SecondaryButton';
import LocalizedLink from './LocalizedLink';


const Title = styled.h3`
    font-size: 24px;
    font-weight: 300;
`;

const Line = styled.hr`
    width:100%;
`;

const Content = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    position: relative;
    margin: 0 auto;

    @media (max-width: 767px) {
        width:100%;
        flex-direction:column;
        margin: -10% auto;
    }
`;

const IconLeft = styled(GatsbyImage)`
    width: 100%;
    @media (max-width: 767px) {
        margin: 0px;
    }
`;

const ComputerLeft = styled(GatsbyImage)`
    width: 470px;
    right: 100px;
    bottom: 10px;

    @media (min-width: 768px) and (max-width: 1023px) {
        width: 290px;
        right: 10px;
        bottom: 60px;
    }

    @media (max-width: 740px) {
        width: 330px;
        right: 40px;
        top: 15px;
    }
`;

const RightCell = styled.div`
    display: flex;
    flex-direction: column;
    width: 450px;
    margin-left:4%;
    margin-bottom:2%;

    @media (max-width: 767px) {
        width:100%;
        margin: 10% auto;
    }
`;

const Text = styled.p`
    font-size: 14px;
    font-weight: 200;
    line-height: 21px;
    margin-top:0px;
    margin-bottom:0px;
`;

const IconMoon = styled.i`
    color: #ffffff;
    margin-right:6%;

    @media (max-width: 767px) {
        width:2vh;
        margin-right:10%;
    }
`;

const TextIcon = styled.p`
    font-size: 14px;
    font-weight: 200;
    line-height: 21px;
    margin-top:0px;
    margin-bottom:0px;
`;

const LeftCell = styled.div`
    position: relative;
    width: 260px;
    margin: 25px auto;

    @media (max-width: 767px) {
        margin: 45px auto;
        width: 260px;
    }
`;

const Video = styled.video`
    width: 100%;
    padding: 24% 6% 0% 5%;
    box-sizing: border-box;     
    margin: 25px auto;
`;

const PictureLeftPixel = styled(GatsbyImage)`
    width: 107%;
    left: -9px;
    top: 39px;
    z-index: -1;
`;

const PictureLeft = styled(GatsbyImage)`
    width: 100%; 
    margin: 25px auto;
    z-index: -1;
`;

const PictureLeftIphoneX = styled(GatsbyImage)`
    width: 125%; 
    margin: 12px 0px 0px -15px;
    z-index: -1;
`;

const RightCells = ({
    textRight, icon, text
}) => {
    return (
        <div style={{
            display: 'flex',
            flexDirection: 'column',
            marginBottom: '4%'
        }}>
            <Text dangerouslySetInnerHTML={{__html: textRight}}></Text>
            <div style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                marginBottom: '4%'
            }}>
                {icon ? <IconMoon className={icon}
                    alt='Representation of icon cell' />
                    : null
                }
                <TextIcon dangerouslySetInnerHTML={{__html: text}}></TextIcon>
            </div>
        </div>
    );
};

RightCells.propTypes = {
    textRight: PropTypes.string,
    icon: PropTypes.string,
    text: PropTypes.string
};

const ContentReverse = ({
    title, rightIcons, leftIcon, urlFooter, computerLeft, titleButton, pictureLeftPixel, videoUrl, pictureLeft, pictureLeftIphoneX, altDescription
}) => {

    const iconsCell = rightIcons ? rightIcons.map(i => {
        return (<RightCells key={i.icon + i.text}
            icon={i.icon}
            textRight={i.textRight}
            text={i.text} />);
    }) : null;

    return (
        <React.Fragment>
            <Title>{title}</Title>
            <Line/>
            <Content>
                <LeftCell>
                    {leftIcon && <IconLeft image={getImage(leftIcon)}
                        alt={altDescription}/>}
                    {computerLeft && <ComputerLeft image={getImage(computerLeft)}
                        alt={altDescription}/>}
                    {pictureLeftPixel && <PictureLeftPixel style={{position: 'absolute'}}
                        image={getImage(pictureLeftPixel)}
                        alt={altDescription}/>}
                    {pictureLeft && <PictureLeft style={{position: 'absolute'}}
                        image={getImage(pictureLeft)}
                        alt={altDescription} />}
                    {pictureLeftIphoneX && <PictureLeftIphoneX style={{position: 'absolute'}}
                        image={getImage(pictureLeftIphoneX)}
                        alt={altDescription} />}
                    {videoUrl && <Video autoPlay
                        loop
                        muted
                        playsInline
                        src={videoUrl} />}
                </LeftCell>

                <RightCell>
                    {iconsCell}
                    <LocalizedLink to={urlFooter}>
                        <SecondaryButton titleButton={titleButton}/>
                    </LocalizedLink>
                </RightCell>
            </Content>

        </React.Fragment>
    );
};

ContentReverse.defaultProps = {right: []};

ContentReverse.propTypes = {
    rightIcons: PropTypes.arrayOf(PropTypes.object),
    title: PropTypes.string,
    leftIcon: PropTypes.object,
    pictureLeftPixel: PropTypes.object,
    computerLeft: PropTypes.object,
    urlFooter: PropTypes.string,
    titleButton: PropTypes.string,
    videoUrl: PropTypes.string,
    pictureLeft: PropTypes.object,
    pictureLeftIphoneX: PropTypes.object,
    altDescription: PropTypes.string
};

export default React.memo(ContentReverse);
