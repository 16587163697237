import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import Helmet from 'react-helmet';
import { graphql } from 'gatsby';

// Layout component
import Layout from '../components/layouts/Layout';
import BannerPicture from '../components/BannerPicture';
import HeaderMap from '../components/HeaderMap';
import CustomersLogos from '../components/CustomersLogos';
import SectionOnlyFooter from '../components/SectionOnlyFooter';
import BackgroundPicture from '../components/BackgroundPicture';
import ContentReverse from '../components/ContentReverse';

const BgDarkBlue = styled.div`
    position:absolute;
    left:0;
    z-index: -10000;
    background-color: #0c499b;
    width: 100%;
    height: 17%;
`;

const LivemapIframe = styled.div`
    display: flex;
    width: 100%;
    height: 560px;
    border-radius: 10px;
    overflow: hidden;
    border: none;
    margin-bottom : 10%;
    box-shadow: 3px 3px 18px 3px rgba(99,99,99,.16863);
`;

const IndexPage = ({ data }) => {
    const { t } = useTranslation('wemapTransportationPage');

    const right = [
        {
            icon: 'icon-round-brush',
            text: t('DESCRIPTION_INTERACTIVE_MAPS_CONTENT')
        },
        {
            icon: 'icon-map-marker',
            text: t('DESCRIPTION_RICH_CONTENT')
        },
        {
            icon: 'icon-search2',
            text: t('DESCRIPTION_USER_SERVICES')
        }];

    const orientation = [
        {textRight: t('DESCRIPTION_ORIENTATION_SECOND')}];

    const connection = [
        {
            icon: 'icon-layers1',
            text: t('DESCRIPTION_INTERACTIVE_MULTI_FLOOR')
        },
        {
            icon: 'icon-location-current',
            text: t('DESCRIPTION_INDOOR_OUTDOOR_ITINERARY')
        },
        {
            icon: 'icon-center_focus_strong',
            text: t('DESCRIPTION_INDOOR_LOCATION')

        },
        {
            icon: 'icon-map2',
            text: t('DESCRIPTION_GEOFENCING')
        }];

    return (
        <React.Fragment>
            <Helmet defer={false}>
                <title>{t('META_TITLE_TRANSPORTATION')}</title>
                <meta name="description"
                    content={t('META_DESCRIPTION_TRANSPORTATION')}/>
            </Helmet>
            <Layout>
                <BackgroundPicture
                    image={data.background.childImageSharp}
                    alt='soccer sneakers on field'
                />
                <div className='wemap-page-index'>
                    <h1 className='wemap-page-index-title'
                        dangerouslySetInnerHTML={{ __html: t('TITLE') }}></h1>

                    <CustomersLogos title={t('TITLE_THEY_USE_TRANSPORTATION')}
                        size='large'
                        customersLogos={[
                            {image: '/images/industries/sncf-logo.png'},
                            {image: '/images/products/indoor/bahn-logo.svg'},
                            {image: '/images/products/wemap-navigation/logo_jc_decaux.png'},
                            {image: '/images/industries/transportation/logo-smmag.svg'},
                            {image: '/images/products/wemap-pro/logo_nimes.svg'},
                            {image: '/images/industries/transportation/keolis.png'},
                            {image: '/images/industries/transportation/transdev_logo.png'},
                            {image: '/images/industries/transportation/ratp.png'},
                            {image: '/images/industries/havre-logo.png'}
                        ]}
                    />

                    <SectionOnlyFooter
                        titleSection={t('TITLE_FACILITATE_USER')}
                        videoUrl3='/images/industries/transportation/jc-decaux-grenoble.mov'
                        pictureRight={data.mobileScreen.childImageSharp}
                        altDescription='mobile gif of interactive map with itineraries'
                        titleButton={t('TITLE_BUTTON_TALK_PROJECT')}
                        urlFooter='/contact/'
                        iconsOnlyFooterComponent={[
                            {
                                icon: 'icon-location-current',
                                text: t('DESCRIPTION_SEAMLESS_EXPERIENCE')
                            }, {
                                icon: 'icon-travel-train',
                                text: t('DESCRIPTION_ALL_TRANSPORTATION')
                            },
                            {
                                icon: 'icon-checkmark',
                                text: t('DESCRIPTION_TURNKEY')
                            },
                            {
                                icon: 'icon-group',
                                text: t('DESCRIPTION_ACCESSIBLE_NAVIGATION')
                            }
                        ]}
                    />

                    <ContentReverse
                        title={t('TITLE_INTERACTIVE_MAP')}
                        leftIcon={data.customizedPhone.childImageSharp}
                        altDescription='mobile with pinpoints and a circuit on map'
                        titleButton={t('TITLE_BUTTON_LEARN_MORE')}
                        rightIcons={right}
                        urlFooter='/products/'
                    />

                    <BgDarkBlue></BgDarkBlue>

                    <HeaderMap customerCicleImageUrl='/images/industries/havre-logo.png'
                        by={t('TEXT_BY')}
                        titleCaseStudy={t('Case Study')}
                        title={t('TITLE_HAVRE')}
                        text={t('TITLE_DESCRIPTION_BY')}
                        textPointInfos={t('TITLE_POINTS_HAVRE')}
                        textViewsInfos={t('TITLE_VIEWS_HAVRE')}
                        descriptionCustomer={t('TITLE_HAVRE_DESCRIPTION_MAP')}
                        infosRight1={t('TITLE_DESCRIPTION_RIGHT_HV_0')}
                        infosRight2={t('TITLE_DESCRIPTION_RIGHT_HV_1')}
                        infosRight3={t('TITLE_DESCRIPTION_RIGHT_HV_2')}
                        infosRight4={t('TITLE_DESCRIPTION_RIGHT_HV_3')}
                    />

                    <LivemapIframe>
                        <iframe title="livemap"
                            width="100%"
                            height="100%"
                            src="https://livemap.getwemap.com/embed.html?emmid=16215#/search@49.4896413,0.1185724,12.00"
                            allowfullscreen
                            frameborder="0"></iframe>
                    </LivemapIframe>

                    <SectionOnlyFooter
                        titleSection={t('TITLE_PROMOTE_TRANSPORTATION')}
                        pictureRight={data.kioskMockup.childImageSharp}
                        videoUrl4='/images/industries/transportation/ratp-kiosk.mp4'
                        altDescription='interactive kiosk with pinpoints'
                        titleButton={t('TITLE_BUTTON_TOUCHSCREENS')}
                        widthPicture='400px'
                        urlFooter='/kiosk/'
                        iconsOnlyFooterComponent={[
                            {
                                icon: 'icon-tablet_mac',
                                text: t('DESCRIPTION_CROSSPLATFORME')
                            },
                            {
                                icon: 'icon-checkmark',
                                text: t('DESCRIPTION_WEMAP_AVAILABLE')
                            },
                            {
                                icon: 'icon-message1',
                                text: t('DESCRIPTION_DISTRIBUTION_TRANSPORT')
                            },
                            {
                                icon: 'icon-printer',
                                text: t('DESCRIPTION_EXPORT_MAPS')
                            }
                        ]}
                    />

                    <ContentReverse
                        title={t('TITLE_LOST_PUBLIC')}
                        leftIcon={data.helpPhone.childImageSharp}
                        altDescription='Matt Blease illustration of phone with help flag'
                        titleButton={t('TITLE_BUTTON_LEARN_MORE')}
                        rightIcons={orientation}
                        urlFooter='/augmented-reality/'
                    />

                    <SectionOnlyFooter
                        titleSection={t('TITLE_INSTANT_ORIENTATION')}
                        videoUrl='/images/industries/transportation/station-train-nav.mp4'
                        pictureRight={data.whiteMobile.childImageSharp}
                        altDescription='mobile with augmented reality in railway station'
                        titleButton={t('TITLE_BUTTON_TALK_EXPERT')}
                        widthPicture='400px'
                        urlFooter='/contact/'
                        iconsOnlyFooterComponent={[
                            {
                                icon: 'icon-location-current',
                                text: t('DESCRIPTION_ORIENTATION_DIRECTION')
                            },
                            {
                                icon: 'icon-search2',
                                text: t('DESCRIPTION_VISUAL_SEARCH')
                            },
                            {
                                icon: 'icon-travel-walk',
                                text: t('DESCRIPTION_SIMPLIFIED_STEP')
                            },
                            {
                                icon: 'icon-mobile',
                                text: t('DESCRIPTION_AVAILABLE_MOBILE_WEB')
                            }
                        ]}
                    />

                    <ContentReverse
                        title={t('TITLE_INDOOR_CONNECTION')}
                        leftIcon={data.itineraryTrain.childImageSharp}
                        altDescription='mobile with an indoor itinerary in railway station'
                        titleButton={t('TITLE_BUTTON_LEARN_MORE_INDOOR')}
                        rightIcons={connection}
                        urlFooter='/indoor-maps/'
                    />

                    <BannerPicture backgroundBanner={data.banner.childImageSharp}
                        alt='Footer banner with a woman waiting for a train'
                        titleBannerPicture={t('TITLE_BANNER_CASE_STUDIES')}
                        titleButton={t('TITLE_BUTTON_TRY_OUT_NOW')}
                        buttonUrl='/contact/'
                    />

                </div>
            </Layout>
        </React.Fragment>
    );
};

IndexPage.propTypes = {data: PropTypes.object};

export default IndexPage;

export const query = graphql`{
  background: file(relativePath: {eq: "background-image/transportation-bg.png"}) {
    childImageSharp {
      gatsbyImageData(quality: 100, layout: FULL_WIDTH)
    }
  }
  banner: file(
    relativePath: {eq: "industries/transportation/banner_transportation.png"}
  ) {
    childImageSharp {
      gatsbyImageData(quality: 75, layout: FULL_WIDTH)
    }
  }
  webApps: file(relativePath: {eq: "products/wemap-web/wemap_web_apps.png"}) {
    childImageSharp {
      gatsbyImageData(quality: 75, width: 420, layout: FIXED)
    }
  }
  customizedPhone: file(
    relativePath: {eq: "industries/transportation/custumized-map-poi.png"}
  ) {
    childImageSharp {
      gatsbyImageData(quality: 75, layout: FULL_WIDTH)
    }
  }
  kioskMockup: file(
    relativePath: {eq: "products/wemap-kiosk/kiosk-with-background-v1.png"}
  ) {
    childImageSharp {
      gatsbyImageData(quality: 75, width: 270, layout: FIXED)
      fluid(quality: 75) {
        ...GatsbyImageSharpFluid_withWebp
      }
    }
  }
  helpPhone: file(relativePath: {eq: "industries/transportation/help-phone.png"}) {
    childImageSharp {
      gatsbyImageData(quality: 75, layout: FULL_WIDTH)
    }
  }
  mobileScreen: file(relativePath: {eq: "products/indoor/mobile_screen.png"}) {
    childImageSharp {
      gatsbyImageData(quality: 75, width: 270, layout: FIXED)
      fluid(quality: 75) {
        ...GatsbyImageSharpFluid_withWebp
      }
    }
  }
  itineraryTrain: file(
    relativePath: {eq: "industries/transportation/itineraire-gare.png"}
  ) {
    childImageSharp {
      gatsbyImageData(quality: 75, layout: FULL_WIDTH)
    }
  }
  whiteMobile: file(
    relativePath: {eq: "products/wemap-ar/white_mobile_augmented-reality.png"}
  ) {
    childImageSharp {
      gatsbyImageData(quality: 75, width: 294, layout: FIXED)
      fluid(quality: 75) {
        ...GatsbyImageSharpFluid_withWebp
      }
    }
  }
  uniqueHeritages: file(
    relativePath: {eq: "industries/culture/wemap-unique-heritages.png"}
  ) {
    childImageSharp {
      gatsbyImageData(quality: 75, height: 470, layout: FIXED)
    }
  }
}
`;
